<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renameNetwork from '@/mixins/renameNetwork';
import changePrivatNetworkDescription from '@/mixins/changePrivatNetworkDescription';
export default {
  name: 'PrivateNetworkContextMenu',
  mixins: [renameNetwork, showErrorOpenStackModal, changePrivatNetworkDescription],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    // isLoading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    servers() {
      return this.$store.getters['moduleStack/servers'];
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    id() {
      return this.tariff.id;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const networkAdminStatus = this.tariff.admin_state_up !== true ? 'true' : 'false';
      const arr = [];
      arr.push([
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: 'edit',
          id: this.id,
          handler: 'func',
          action: {
            network: {
              name: this.tariff.name,
            },
          },
          // disabled: true,
          // disabled: ![13, 15].includes(status),
        },
        {
          key: networkAdminStatus,
          id: this.id,
          handler: 'state',
          label: !this.tariff.admin_state_up,
          action: {
            admin_state_up: !this.tariff.admin_state_up,
          },
          // disabled: true,
          // disabled: ![13, 15].includes(status),
        },
        {
          key: 'delete',
          color: 'del',
          handler: 'delete',
          // action: { id: this.id },
          action: 'delete',
          // disabled: true,
          // disabled: ![15, 4, 31, 32, 40].includes(status),
        },
      ]);
      return arr;
    },
  },
  mounted() {},
  beforeUnmount() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'state') this.askSure(item);
      if (item.action && item.handler === 'delete') this.deleteNetwork(item);
      if (item.action && item.key === 'edit') this.renameNetwork(this.tariff.name, item);
      if (item.handler === 'descr') this.changePrivatNetworkDescription(this.tariff);
    },
    changeAdminState(item) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateNetwork', {
        network: item.action,
        id: item.id,
        item: 'admin_state_up',
      });
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action.label}`) }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.changeAdminState(action)
                  .then(data => {
                    this.isProcessing = false;
                    mode = data;
                    console.log(action.key);
                    res = `success.${action.key}`;
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      console.log(res);
                      self.text = this.$t(`modal.sure.${res}`, {
                        msg: this.$t(`modal.sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteNetwork() {
      const that = this;
      let self;
      const selfName = 'ServerAction';

      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: `${this.$t('delete')} <b>${this.tariff.name}</b> (id: ${this.id})?`,
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                let res = '';
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentNetwork(this.id).then(async data => {
                  if (data === 204) {
                    await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
                    await this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t('modal.sure.success.delete');
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$modals.close();
                              this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
                              this.$router
                                .push({
                                  name: 'containerNetwork',
                                  query: { filter_by: 'name', value: '' },
                                })
                                .catch(() => {});
                            },
                          },
                        },
                      };
                    }, delay);
                  } else {
                    this.$modals.close();
                    this.showError(data);
                  }
                });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteCurrentNetwork(net) {
      return this.$store
        .dispatch('moduleStack/deleteNetwork', {
          net: net,
        })
        .catch(e => e);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Удалить сеть",
    "actions": {
      "edit": "Переименовать сеть",
      "false": "Выключить",
      "true": "Включить",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить сеть",
      "descr": "Изменить описание",
      "description": "Описание приватной сети:"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сеть. Вы уверены?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "success": {
          "true": "Сеть успешно включена",
          "false": "Сеть успешно выключена",
          "delete" : "Сеть успешна удалена."
        },
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
