<template>
  <div class="page-block">
    <div class="page-block__head">
      <div v-if="title || $slots.title" class="page-block__title">
        <slot name="title">
          <h3 class="medium-title">{{ title }}</h3>
        </slot>
      </div>
      <div v-if="$slots.action" class="page-block__action">
        <slot name="action" />
      </div>
    </div>
    <div class="page-block__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBlock',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.page-block {
  //margin-top: 1.5rem;
  margin-top: 0.75rem;

  +breakpoint(sm-and-up) {
    //margin-top: 1.5rem;
  margin-top: 0.75rem;
  }

  &__head {
    flexy(space-between, center);
    //margin-bottom: 1.25rem;
  }

  &__title {
    flex: 1 1 auto;
    margin-bottom: 0.25rem

    :not(:only-child) {
      margin-right: 1rem;
    }
  }

  &__action {
    flex: 0 0 auto;
    margin: auto 0 auto auto;
  }
}
</style>
