<template>
  <div class="backup">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="backup__content">
        <div class="backup__form" @keyup.enter="submit">
          <div class="l-col">
            <p class="standart-text" v-html="textForDelete"></p>
            <base-input
              v-model="nameStorage"
              :placeholder="$t('backup.placeholder')"
              size="small"
              :label="$t('backup.label')"
              class="backup-form__field backup-form__field--input"
              @input="onChange"
            >
            </base-input>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
export default {
  name: 'DeleteContainer',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showErrorModal],
  props: {
    storage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameStorage: '',
      currentTaskUuid: '',
      timerCnt: 0,
    };
  },
  computed: {
    isValid() {
      return !!this.nameStorage && this.storage.name === this.nameStorage;
    },
    textForDelete() {
      return this.$t('label', { name: this.storage.name });
    },
    serverId() {
      return this.storage.name;
    },
  },
  mounted() {
    this.$emit('notready');
  },
  methods: {
    onChange(event) {
      // console.log(event, this.storage.name, event === this.storage.name);
      if (event && event === this.storage.name)
        // this.$emit('change', { storage: event, object: this.objects });
        this.$emit('change', { storage: event });
      else this.$emit('notready');
    },
    submit() {
      if (this.isValid) {
        this.$emit('submit', { storage: this.nameStorage });
        // this.$emit('submit', { storage: this.nameStorage, object: this.objects });
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": "Контейнер <b>%{name}</b> будет удалён.",
    "confirm": {
      "fail" : "Не совпадает имя контейнера - удаление отменено"
    },
    "backup": {
      "placeholder": "Введите имя удаляемого контейнера...",
      "label": "Введите имя контейнера для подтверждения:",
      "success": "Контейнер <b>{name}</b>, успешно удалён."
    },
    "btn": {
      "send": "Удалить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup-form {
  &__field {
    &  {
      margin-top: 1.25rem;
    }
  }
}
.backup__send {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  &-btn {
    margin-top: 20px
    margin-right: 0;
    +breakpoint(ms-and-up) {
      margin-top: 60px;
    }
  }

  &-container{
    display: flex;
    align-items: flex-end;

  }

}
</style>
