<template>
  <div class="resize">
    <!--    <label class="resize-row">{{ $t('attach') }}</label>-->
    <div class="l-col">
      <base-input
        v-model="name"
        size="medium"
        :pattern="validateLength(name, 1, 255) ? name : 'tooMach'"
        :label="$t('attach')"
        @input="onChange"
      ></base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'RenameNetwork',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    instance: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lengthName: 255,
      name: this.instance,
    };
  },
  computed: {
    isValid() {
      return this.validateLength(this.name, 1, this.lengthName);
    },
  },
  methods: {
    validateLength(input, min, max) {
      let num = input.length;
      // console.log(num);
      // console.log(num >= min && num <= max);
      return num >= min && num <= max;
    },
    onChange() {
      if (!this.isValid) return this.$emit('notready');
      this.$emit('instance', this.name);
    },
  },
  watch: {
    isValid: {
      handler: function (event) {
        // console.log(event);
        if (!event) this.$emit('notready');
      },
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Имя приватной сети:",
    "sure": {
    "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
