<template>
  <div class="resize">
    <!--    <label class="resize-row standart-text">{{ DescriptionName }}</label>-->
    <div class="l-col">
      <base-input
        v-model="newDescription"
        :label="DescriptionName"
        class="form__field--input"
        :tooltip="hint ? hint : null"
        type="textarea"
        @input="onChange()"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'ChangeDescription',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    description: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newDescription: '',
    };
  },
  computed: {
    DescriptionName() {
      return this.text;
    },
  },
  mounted() {
    this.newDescription = this.description;
  },
  methods: {
    onChange() {
      this.$emit(
        'change',
        this.newDescription.length > 254 ? this.newDescription.substr(0, 254) : this.newDescription
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
}
}
</style>
